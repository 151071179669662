import { handleDefaultModal } from '../../scripts/handleDefaultModal';
import locale from '../../translations/ru.json';

const defaultId = 'default';
export const modalSelector = '#form-modal';
const formSelector = '#modal_form';

const modal = document.querySelector(modalSelector);
const header = modal ? modal.querySelector('[data-modal-header]') : null;
const text = modal ? modal.querySelector('[data-modal-text]') : null;
const buttonText = modal ? modal.querySelector('[data-modal-button]') : null;
const modalHeaderInput = modal ? modal.querySelector('input[name=modal-header]') : null;

const demonstrationForm = document.querySelector(formSelector);
const nameField = demonstrationForm ? demonstrationForm.querySelector('[name=name]') : null;
const emailField = demonstrationForm ? demonstrationForm.querySelector('[name=email]') : null;
const phoneField = demonstrationForm ? demonstrationForm.querySelector('[name=phone]') : null;
const messageField = demonstrationForm ? demonstrationForm.querySelector('[name=message]') : null;

let foundURL = false;

const content = {
  default: {
    header: locale['components.modalForm.container.defaultTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-price': {
    header: locale['components.modalForm.container.getPriceTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-func': {
    header: locale['components.modalForm.container.getFuncTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-demo': {
    header: locale['components.modalForm.container.getDemoTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'find-out-why': {
    header: locale['components.modalForm.container.findOutWhyTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-estimate': {
    header: locale['components.modalForm.container.getEstimate'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-brief': {
    header: locale['components.modalForm.container.getBriefTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
  },
  'get-presentation': {
    header: locale['components.modalForm.container.getPresentationTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
    toForm: locale['components.modalForm.container.getPresentationHybridToForm'],
  },
  'get-presentation-hybrid': {
    header: locale['components.modalForm.container.getPresentationTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
    toForm: locale['components.modalForm.container.getPresentationHybridToForm'],
  },
  'get-presentation-online': {
    header: locale['components.modalForm.container.getPresentationTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
    toForm: locale['components.modalForm.container.getPresentationOnlineToForm'],
  },
  'get-presentation-offline': {
    header: locale['components.modalForm.container.getPresentationTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
    toForm: locale['components.modalForm.container.getPresentationOfflineToForm'],
  },
  'get-presentation-activity': {
    header: locale['components.modalForm.container.getPresentationTitle'],
    text: '',
    button: locale['components.modalForm.container.defaultButton'],
    toForm: locale['components.modalForm.container.getPresentationActivityToForm'],
  },
};

const onClickShowModal = (e) => {
  location.hash = e.currentTarget.dataset.modalForm;
  resetForm();
  changeContent(
    content[
      e.currentTarget && e.currentTarget.dataset.modalForm && content[e.currentTarget.dataset.modalForm]
        ? e.currentTarget.dataset.modalForm
        : defaultId
    ],
  );

  handleDefaultModal(modalSelector, () => {
    resetForm();
  });
};

const showModalFromUrl = (btn) => {
  resetForm();
  changeContent(content[btn ? btn : defaultId]);
  handleDefaultModal(modalSelector, () => {
    resetForm();
  });
};

const changeContent = (contentItem) => {
  if (header) {
    header.innerHTML = contentItem.header;
  }
  if (text) {
    text.innerHTML = contentItem.text;
  }
  if (buttonText) {
    buttonText.innerHTML = contentItem.button;
  }
  if (modalHeaderInput) {
    modalHeaderInput.value = contentItem.toForm ? contentItem.toForm : contentItem.header;
  }
};

const resetForm = () => {
  if (demonstrationForm) {
    demonstrationForm.classList.remove('success');
    demonstrationForm.reset();
  }
  if (nameField) {
    nameField.parentElement.classList.remove('error');
    nameField.disabled = false;
  }
  if (emailField) {
    emailField.parentElement.classList.remove('error');
    emailField.disabled = false;
  }
  if (phoneField) {
    phoneField.parentElement.classList.remove('error');
    phoneField.disabled = false;
  }
  if (messageField) {
    messageField.disabled = false;
  }
};

const checkModals = () => {
  document.querySelectorAll('[data-modal-form]').forEach((btn) => {
    if (window.location.hash === '#' + btn.getAttributeNode('data-modal-form').value) {
      if (!foundURL) {
        foundURL = true;
        showModalFromUrl(btn.getAttributeNode('data-modal-form').value);
      }
    }
  });
};

let hashCheck = false;

window.addEventListener('hashchange', (e) => {
  hashCheck = false;
  document.querySelectorAll('[data-modal-form]').forEach((btn) => {
    if (e.newURL.match('#' + btn.getAttributeNode('data-modal-form').value)) {
      hashCheck = true;
      showModalFromUrl(location.hash.slice(1));
    }
  });
  if (!hashCheck) {
    modal.style.visibility = 'hidden';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    modal.classList.remove('visible');
    if (!document.body.classList.contains('disable')) document.body.style.overflow = 'auto';
  }
});

export default function () {
  document.querySelectorAll('[data-modal-form]').forEach((btn) => btn.addEventListener('click', onClickShowModal));
  checkModals();
}
