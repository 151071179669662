import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import InitAutoplay from '../../scripts/initAutoplay';
Swiper.use([Navigation, Pagination, Autoplay]);

const reviewsTopConfig = {
  pagination: {
    el: '.er_reviews_top__inner__pagination',
    clickable: true,
  },
  spaceBetween: 30,
  resizeObserver: true,
  grabCursor: true,
  navigation: {
    nextEl: '.er_reviews_top__inner__next',
    prevEl: '.er_reviews_top__inner__prev',
  },
};

const reviewsBottomConfig = {
  pagination: {
    el: '.er_reviews_bottom__inner__pagination',
    clickable: true,
  },
  spaceBetween: 30,
  resizeObserver: true,
  grabCursor: true,
  navigation: {
    nextEl: '.er_reviews_bottom__inner__next',
    prevEl: '.er_reviews_bottom__inner__prev',
  },
};

export default () => {
  const reviewsTopSlider = new Swiper('.er_reviews_top_container', reviewsTopConfig);
  const reviewsBottomSlider = new Swiper('.er_reviews_bottom_container', reviewsBottomConfig);
  window.addEventListener('scroll', () => {
    InitAutoplay(reviewsTopSlider, 'er_reviews_top', 6000);
    InitAutoplay(reviewsBottomSlider, 'er_reviews_bottom', 6000);
  });
};
