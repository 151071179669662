const slidersOptionsArray = [
  {
    mockContainer: '.branding_section__inner__card__img__container',
    mockSelector: '.branding_section__inner__card__img__container__mock',
    buttonsSelector: '.branding_section__inner__card .branding_section__inner__card__content__buttons .branding_section__inner__card__content__buttons__circle[data-branding-number]',
    imagesSelector: '.branding_section__inner__card .branding_section__inner__card__img img[data-branding-number]',
    datasetName: 'brandingNumber'
  }
]


export default () => {
  const ACTIVE_CLASSNAME = 'active';
  const LAPTOP_CLASSNAME = 'laptop';

  const sliderInit = (mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName) => {
    const buttons = document.querySelectorAll(buttonsSelector);
    const images = Array.from(document.querySelectorAll(imagesSelector));
    const mock = document.querySelectorAll(mockSelector);
    const mockOverlay = document.querySelector(mockContainer);
    const onButtonClick = (event) => {
      const slideNumber = event.currentTarget.dataset[datasetName];
    
      if (!event.currentTarget.classList.contains(ACTIVE_CLASSNAME) && slideNumber) {
        const imageToShow = images.filter((element) => element.dataset[datasetName] === slideNumber);
    
        if (slideNumber && imageToShow.length) {
          buttons.forEach((element => element.classList.remove(ACTIVE_CLASSNAME)));
          images.forEach((element => element.classList.remove(ACTIVE_CLASSNAME)));
          mock.forEach((element => element.classList.remove(ACTIVE_CLASSNAME)));
          mockOverlay.classList.remove(LAPTOP_CLASSNAME);
    
          event.currentTarget.classList.add(ACTIVE_CLASSNAME);
          imageToShow.forEach(element => {
            element.classList.add(ACTIVE_CLASSNAME);
            if (!element.classList.contains("laptop")) {
              mock.forEach((element => element.classList.add(ACTIVE_CLASSNAME)));
            } else {
              mockOverlay.classList.add(LAPTOP_CLASSNAME);
            }
          });
        }
      }
    };
    
    buttons.forEach((el) => { el.addEventListener('click', onButtonClick) });
  }

  slidersOptionsArray.forEach(({ mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName }) => sliderInit(mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName));
}