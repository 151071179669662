import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

let associationsSlider = null;

export default () => {
  const initSlider = () => {
    if (window.matchMedia('(max-width: 767px)').matches && !!!associationsSlider) {
      associationsSlider = new Swiper('.er_associations__inner__slider', {
        grabCursor: true,
        navigation: {
          nextEl: '.er_associations__arrows__next',
          prevEl: '.er_associations__arrows__prev',
        },
        pagination: {
          el: '.er_associations__pagination',
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 50,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 90,
          },
        },
      });
    }
  };
  initSlider();
  window.addEventListener('resize', initSlider, false);
};
