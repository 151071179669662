const API_URL = `https://blog.eventrocks.ru/ghost/api/v4/content/posts/?key=2496a935a5caa3ceb9413fdd4a&include=tags`;

export default () => {
  fetch(API_URL)
    .then((response) => {
      if (!response.ok) {
        document.querySelector('.blog_section').remove();
      }
      response.json().then((data) => {
        const posts = data.posts;
        let html = ``;
        let number = 86
        if (posts) {
          posts.forEach((el, key) => {
            number = number + 1;
            if (key < 3) {
              if (el.url && el.feature_image && el.tags[0] && el.tags[0].name && el.title) {
                html += `
            <a class='blog_section__inner__item' href='${el.url}' target='_blank'>
              <div class='blog_section__inner__item__img'>
                <img src='${el.feature_image}' alt='Платформа для мероприятий Event Rocks, фото ${number}'/>
              </div>
              <div class='blog_section__inner__item__content'>
               <span>${el.tags[0].name}</span>
                <h3> ${el.title} </h3>
              </div>
            </a>
          `;
              }
            }
          });
          if (!html) document.querySelector('.blog_section').remove();
          else document.querySelector('.blog_section__inner').innerHTML = html;
        } else {
          document.querySelector('.blog_section').remove();
        }
      });
    })
    .catch((error) => {
      document.querySelector('.blog_section').remove();
      console.log(error);
    });
};
